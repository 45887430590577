@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #E3000B;
  border-radius: 10px;
}

.angular-editor-textarea {
  background: white !important;
  border: 1px solid rgba(2, 42, 58, 0.4) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  min-height: 80px !important;
}

.ng-select.custom {
  border: 1px solid rgba(2, 42, 58, 0.3);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 47px;
}

.ng-select.custom .ng-select-container {
  min-height: 47px;
  border-radius: 6px;
}

.ng-select.custom .ng-select-container .ng-value-container .ng-value {
  background-color: #e3000b33;
}

.ng-select.custom-filter {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20.5px;
  font-weight: bold;
  min-height: 38px;
}

.ng-select.custom-filter .ng-select-container {
  min-height: 38px;
  border-radius: 6px;
  border-radius: 20.5px;
}

.ng-select.custom-filter .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  z-index: 888;
  margin-right: 10px;
}

.ng-select.custom-geo {
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid black;
  border-top: none;
  border-right: none;
  border-left: none;
}

.ng-select.custom-geo .ng-select-container {
  border-radius: 0;
  border-top: none;
  background: transparent;
  border-right: none;
  border-left: none;
}

.ng-select.custom-geo .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  z-index: 888;
  margin-right: 10px;
}

:root {
  scrollbar-color: #E3000B rgba(0, 0, 0, 0.4) !important;
  scrollbar-width: thin !important;
}

.popover {
  max-width: 400px;
  width: 350px;
}

.grecaptcha-badge {
  display: none !important;
}

body {
  background-color: #E5E5E5 !important;
  font-family: StagSans !important;
}

.go-back {
  font-size: 20px;
  line-height: 29px;
  color: #E3000B;
  text-decoration: none !important;
}

.go-back:hover {
  color: #e3000bab;
}

.margen-nav {
  margin-top: 115px;
}

.ngx-pagination .current {
  background: white !important;
  color: #E3000B !important;
  font-weight: bold !important;
}

.ngx-pagination a {
  color: #979797 !important;
}

.ngx-pagination {
  padding-left: 0;
  background: white;
  border-radius: 20px;
}

.ngx-pagination .pagination-next a::after, .ngx-pagination .pagination-next.disabled::after {
  content: "" !important;
  font-family: FontAwesome !important;
}

.ngx-pagination .pagination-next a::after {
  color: black;
}

.ngx-pagination .pagination-previous a::before, .ngx-pagination .pagination-previous.disabled::before {
  content: "" !important;
  font-family: FontAwesome !important;
}

pdf-viewer {
  display: flex;
  height: 75vh;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: StagSans;
  src: url(./assets/fonts/StagSans-Regular.ttf);
  font-style: normal;
}
.font-StagSans {
  font-family: StagSans !important;
}

@font-face {
  font-family: FontAwesome;
  src: url(./assets/fonts/FontAwesome5.otf);
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 0.7s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px black;
  border-bottom-color: #E3000B;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.ng-select.custom-filter-2 {
  border: 1px solid rgba(2, 42, 58, 0.3);
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
}

.ng-select.custom-filter-2 .ng-select-container {
  border-radius: 6px;
  background: transparent;
}

.ng-select.custom-filter-2 .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  z-index: 888;
  margin-right: 10px;
}

.ng-select.input-custom-filter-ngselect {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20.5px;
  font-weight: bold;
  width: 170px;
}

.ng-select.input-custom-filter-ngselect .ng-select-container {
  border-radius: 20.5px;
  background: white;
}

.ng-select.input-custom-filter-ngselect .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  z-index: 888;
  margin-right: 10px;
}